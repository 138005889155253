import React from "react";
import "../styles/mindbody.scss"
import accountStyles from "../components/account.module.scss"
import Layout from '../components/layout-default'
import Breadcrumbs from "../components/breadcrumbs";
import MindBodyWidget from "../components/mindbody";
import ErrorBoundary from "../components/ErrorBoundary";

const crumbs = [
    {
        label: "Home",
        link: "",
    },
    {
        label: "Prospect",
        link: "Prospect"
    },
];

const Prospects = () => {
    return (
        <Layout
            title="Best Studio Rentals in Orange County | RF Dance"
            description="Discover the best studio rentals in Orange County and unlock a world of possibilities for your next project. Book now and start turning your vision into reality."
            pathname="account"
            noHero="no-hero">
            <div className="container">
                <Breadcrumbs crumbs={crumbs} />
                <div id="prospects_custom">
                    <div className='topContent'>
                        <div className='topLogo'>
                                <img class="healcode-prospect" src="https://s3.amazonaws.com/files.healcode.com/prospects/46959/original.jpeg" />
                        </div>
                        <div className='topContent'>
                            <h3>Your ticket into the world of Latin dance</h3>
                            <p>Get 1 free salsa, bachata or cumbia class by Orange County's favorite homegrown dance school! Take advantage of one RF Dance class at no cost! Learn to dance bachata, salsa, cumbia, and more!</p>
                        </div>
                    </div>
                    <ErrorBoundary>
                        <MindBodyWidget
                            id="d44695909b0"
                            type="prospects"
                            widgetPartner="object"
                            widgetId="d44695909b0"
                            widgetVersion="1"
                        />
                    </ErrorBoundary>
                </div>
            </div>
            <style jsx="jsx">{`
#prospects_custom {
    max-width: 750px;
    text-align: center !important;
    padding: 30px 40px;
    box-shadow: 0px 0px 10px #e3e3e3;
    font-size: 16px;
    border-radius: 10px;
    box-sizing: border-box;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    width: 90%;
}
img.healcode-prospect {
    height: 8em;
    max-width: 100%;
}
#prospects_custom .healcode-prospect-promo-text {
    display:none;
}
#prospects_custom img.healcode-prospect-logo {
    display: none;
}
#prospects_custom .hc_prospect {
    padding: 0 50px;
}
            
            div#prospects_custom {margin: 50px auto;    margin-top: 10px;}
            div#prospects_custom .hc-privacy-footer{display:none;}
            div#prospects_custom .hc-prospect-field input, div#prospects_custom .hc-prospect-field select, div#prospects_custom .hc-prospect-field textarea {
                width: 100%;
                height: 44px;
                padding: 0 15px;
                border-radius: 5px;
                border: 1px solid #d2d2d2;
                background: transparent;
                font-size: 17px;
                box-sizing: border-box;
                -webkit-box-sizing: border-box;
                -moz-box-sizing: border-box;
            }
            div#prospects_custom .hc_prospect textarea {
                height: 110px;
                padding: 10px 20px;
            }
            div#prospects_custom .parsley-errors-list li {
                color: #fff;
                padding: 12px 15px;
                font-weight: 400;
                margin-bottom: -8px;
                margin-top: 5px;
            }
            div#prospects_custom .parsley-errors-list {
                text-align: left;
                background: #FF0000;
                border-radius: 5px;
            }
            div#prospects_custom .hc_prospect  .hc-prospect-field {
                margin-bottom: 15px;
            }
            div#prospects_custom .communication-wrapper .checkbox-wrapper span.hc-prospect-field {
                margin-bottom: 0;
                font-size: 16px;
                font-weight: 500;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            div#prospects_custom .communication-wrapper .checkbox-wrapper {
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                margin-bottom: 15px;
            }  
            div#prospects_custom .communication-wrapper .communication-text {
                font-size: 18px;
                font-weight: 600;
                margin-bottom: 10px;
            }
            div#prospects_custom .communication-wrapper .checkbox-wrapper input {
                width: 20px;
                height: 20px;
                margin-right: 5px;
            }
            div#prospects_custom .communication-wrapper {
                background: #f1f1f1;
                padding: 20px 0;
                border-radius: 5px;
                text-align: center;
            }
            div#prospects_custom .communication-wrapper .communication-settings:last-child .checkbox-wrapper {
                margin-bottom: 0;
            }
            div#prospects_custom .hc-actions input {
                background: #1979d9;
                padding: 12px 40px;
                border-radius: 40px;
                color: #fff;
                font-size: 20px;
                display: inline-block;
                cursor: pointer;
                border: 1px solid #1979d9;
                margin: 30px auto;
                text-align: center;
                display: block;
            }
            div#prospects_custom .hc-actions input:hover {
                background: #fff;
                color: #1979d9;
            }
            div#prospects_custom .hc-prospect-field input::placeholder, div#prospects_custom .hc-prospect-field textarea::placeholder { 
                color: #3c3c3c;
            }

            div#prospects_custom .hc-prospect-field input:-ms-input-placeholder, div#prospects_custom .hc-prospect-field textarea::placeholder { 
            color: #3c3c3c;
            }

            div#prospects_custom .hc-prospect-field input::-ms-input-placeholder, div#prospects_custom .hc-prospect-field textarea::placeholder  {color: #3c3c3c;
            }
            @media screen and (max-width: 767px) {
                #prospects_custom  {
                    padding: 10px 25px;
                }

                #prospects_custom .hc_prospect {
                    padding: 0;
                }
                div#prospects_custom .hc-actions input {
                    margin-top: 10px;
                }
            }
            

      `}</style>
        </Layout>
    )
}

export default Prospects